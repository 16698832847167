<template>
  <div class="address">
    <center-title title="产品方案"></center-title>
    <router-link to="/selectmygoodslist" class="el-button whtie submit" 
      >产品浏览</router-link>

   
      <router-link to="/mygoodslist" class="el-button whtie submit"  style="margin-bottom: 2%;"
      >产品方案</router-link>
      <router-link to="/existinglist" class="el-button whtie submit"  style="margin-bottom: 2%;"
      >临时产品方案</router-link>
    <div class="table">
      <div class="table-item th row lighter">
        <div class="item" style="width: 40%;">方案名称</div>
        <div class="item" style="width: 30%;">创建时间</div>
        <!-- <div class="item">删除</div>
        <div class="item">邮编</div>
        <div class="item">电话/手机</div>-->
        <div class="item"  style="width: 30%;">操作</div> 
      </div>
      <div
        class="table-item row xs"
        v-for="(item, index) in myGoodsList"
        :key="index"
      >
        <div class="item" style="width: 40%">{{ item.product_set_name }}</div>
        <div class="item" style="width: 30%;">{{item.in_time }}</div>    

     
        <div class="item status-list" style="width: 30%;">

          <div
            class="status-item size"          @click="editgoodslist(item.id)"
            :class="{ active: item.is_default }"
            
          >
        查看
          </div>
         
          <div class="status-item" @click="delMyGoodsDetail(item.id)">删除</div>
      
        </div>
      </div>
      <div class="table-item row xs" v-if="!myGoodsList.length">
        <div class="item flex1">暂无商品集</div>
      </div>
    </div>
  </div>
</template>
<script>
import CenterTitle from "../components/CenterTitle";
export default {
  name: "",
  props: {},
  components: {
    "center-title": CenterTitle,
  },
  data() {
    return {
      myGoodsList: [],
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 获取地址列表
    getMyGoodsList() {
      this.$api.getMyGoodsListApi().then((res) => {
        console.log(res.data);
        this.myGoodsList = res.data.lists;
      });
    },
  
    // 删除我的商品集
    delMyGoodsDetail(id) {
      this.$api
        .delMyGoodsDetailApi({
          id,
        })
        .then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.getMyGoodsList();
        });
    },


     // 编辑已存商品集
     editgoodslist(id) {    
      this.$router.push('/editgoodslist?id='+id);
    },

  },
  created() {
    this.getMyGoodsList();
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang='scss' scoped>
.add {
  border: $-solid-border;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}
.table {
  border-top: $-solid-border;
  border-left: $-solid-border;
  .table-item {
    align-items: inherit;
    &.th {
      background-color: $-color-center;
    }
    .item {
      min-height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px 0;
      line-height: 1.5;
      border-right: $-solid-border;
      border-bottom: $-solid-border;
      &.status-list {
        padding: 0 20px;
        justify-content: space-between;
        &:not(.active) {
          .status-item {
            &:hover {
              color: $-color-primary;
              cursor: pointer;
            }
          }
        }
        .status-item {
          &.size {
            padding: 7px 8px;
            &.active {
              color: $-color-primary;
              border: 1px solid $-color-primary;
              background-color: rgba($color: $-color-primary, $alpha: 0.2);
              border-radius: 4px;
            }
          }
        }
      }
      &:nth-child(1) {
        width: 15%;
      }
      &:nth-child(2) {
        width: 32%;
        text-align: left;
        padding:10px 30px 10px 50px;
        justify-content: flex-start;
      }
      &:nth-child(3) {
        width: 14%;
      }
      &:nth-child(4) {
        width: 16%;
      }
      &:nth-child(5) {
        width: 23%;
      }
    }
  }
}
</style>